import React from "react"

import { RouteComponentProps } from "@reach/router"

import { ContentWrapper, H1, Layout, P, Wrapper } from "../components"
import Header from "../components/blocks/Header"
import SEO from "../components/Seo"
import { useTranslations } from "../utils/intl"

const NotFoundPage: React.FC<RouteComponentProps> = () => {
  const { it } = useTranslations()
  return (
    <Wrapper>
      <Layout>
        <SEO
          title={it(["The page could not be found", "Sidan kunde inte hittas"])}
        />
        <Header
          css={{
            position: "absolute",
            left: 0,
            right: 0,
            "@media(max-width: 800px)": { display: "none" }, // TODO: Display mobile header
          }}
        />
        <ContentWrapper
          css={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <H1>
            {it(["The page could not be found", "Sidan kunde inte hittas"])}
          </H1>
          <P css={{ maxWidth: "30em" }}>
            {it([
              "The page you are looking for can not be reached. It may be because it has been removed or moved.",
              "Sidan du försökte besöka finns inte. Den kanske har bytt namn eller tagits bort.",
            ])}
          </P>
        </ContentWrapper>
      </Layout>
    </Wrapper>
  )
}

export default NotFoundPage
